$primary-color: #7583f7;
$secondary-color: #ee689b;

header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
}

.pd-interview {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 65px);
  .interview-finished {
    .card {
      max-width: 600px;
  
      .ant-card-head-title p {
        margin-bottom: 5px;
        color: #4e4e4e;
      }
  
      p {
        margin-bottom: 15px;
      }
    }
  }
}

.int {
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  margin: auto;
  background: #ffffffd8;
  height: 100%;
  position: sticky;
  z-index: 9999;

  @media screen and (max-width: 1050px) {
    padding: 0 !important;
  }

  @media screen and (max-width: 990px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ant-card-head-title {
    padding: 10px 0;
  }

  &__title-and-description {
    h1 {
      margin: 0;

      @media screen and (min-width: 766px) {
        margin: 0.2em 0;
      }

      @media screen and (max-width: 766px) {
       font-size: 20px;
      }
    }
  }

  .mat-card {
    width: 100%;
    min-height: 88vh;
    max-height: 100%;
    padding: 1em;
    border-radius: 10px;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 0 9px 18px 0 #5a5a5a4d;
    
    @media screen and (min-width: 990px) {
      max-width: 63%;
      margin: 20px auto;
    }
    @media screen and (max-width: 990px) {
      min-height: 100vh;
    }

    .ant-card-head {
      border: none;
    }

    .ant-card-body {
      @media screen and (max-width: 600px) {
        padding: 24px 0;
      }
    }

    .mat-card-content {
      margin: 0 auto 2em;
      padding-left: 2em;
      padding-right: 2em;

      @media screen and (min-width: 1200px) {
        max-width: 70%;
      }

      @media screen and (max-width: 766px) {
        max-width: 100%;
      }

      @media screen and (max-width: 500px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  &__steps {
    overflow: auto;
    padding-top: 1em;
    position: absolute;
    bottom: 22px;
    left: 0;
    right: 0;
    
    @media screen and (min-width: 600px) {
      padding: 10px 25px;
    }

    @media screen and (max-height: 700px) {
      position: initial;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar {
      height: 6px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: rgb(255, 255, 255);
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.473);
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.664);
      }
    }

    .ant-steps {
      .ant-steps-item-title {
        width: 100%;
        color: #414141 !important;

        .q-text {
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 600;
        }

        .ant-steps-item-subtitle {
          font-weight: 600;
        }
      }
      .ant-steps-item-active {
        .ant-steps-icon-dot {
          background: $primary-color;
        }
      }
    }
  }
}

.video-test__video-section {
  position: relative;
  .record-btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    z-index: 9;
    display: flex;
    justify-content: center;

    button {
      height: 40px;
      border: none;
      color: #fff;
      border-radius: 20px;
      transition: all .3s;
      margin: 0 5px;

      &:hover {
        animation: none;
        transform: scale(1.07);
      }
    }

    button.ant-btn.ant-btn-default {
      background: $primary-color;
    }

    button.ant-btn.ant-btn-danger {
      background: $secondary-color;
      
      &:enabled {
        animation: pulse 1.2s infinite;
      }

      &:hover {
        color: #fff;
      }

      @media screen and (min-width: 400px) {
        min-width: 11em;
      }

      @-webkit-keyframes pulse {
        0% {
          -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 234, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 234, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 30px rgba(231, 0, 231, 0);
        }
      }
      @keyframes pulse {
        0% {
          -moz-box-shadow: 0 0 0 0 rgba(255, 0, 234, 0.4);
          box-shadow: 0 0 0 0 rgba(255, 0, 234, 0.4);
        }
        70% {
          -moz-box-shadow: 0 0 0 10px rgba(255, 0, 234, 0);
          box-shadow: 0 0 0 10px rgba(255, 0, 234, 0);
        }
        100% {
          -moz-box-shadow: 0 0 0 30px rgba(231, 0, 231, 0);
          box-shadow: 0 0 0 30px rgba(231, 0, 231, 0);
        }
      }
    }
  }
}

.vi-messages {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #e4e4e4;
}

.ant-popover {
  z-index: 9999999999;
}