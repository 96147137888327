.header {
  height: 65px;
  padding-left: 1em;
  padding-right: 1em;
  background: white;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 700px) {
    padding-left: 4em;
    padding-right: 4em;
    justify-content: space-between;
  }

  .mat-toolbar-row {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 700px) {
      justify-content: flex-start;
    }

    .logo {
      width: 210px;
  
      img {
        width: 100%;
      }
    }
  }
}
