@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body, html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7 !important;
}

button {
  font-weight: 700 !important;
  text-shadow: none !important;
}

.ant-modal button.ant-btn.ant-btn-primary {
  border: none;
  background: #7583f7;
}
